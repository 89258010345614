
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY, OPERATOR } from "@/core/config/Constant";
import { ISearchField } from "@/core/entity/ISearchField";
import store from "@/store";
import { SearchEntityEvent } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";
import PaymentViewCard from "@/views/payment/PaymentViewCard.vue";

export default defineComponent({
  name: "Recent",
  components: {PaymentViewCard, RecentCards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Payment Lists',
        [
          {link: false, router: '', text: 'Recent Payments'}
        ]
      )
    })
    const ids = ref("")
    const recentList = CookieService.get(COOKIE_KEY.RECENT_PAYMENTS);
    if (recentList) {
      ids.value = JSON.parse(recentList).join(",")
    }
    const filter: ISearchField[] = [
      {column: 'paymentId', type: 'HIDDEN', operator: OPERATOR.IN, value: ids.value ? ids.value : '0', name: ''},
    ]
    const paymentSearchPage = computed(() => store.state.PaymentModule.paymentSearchPage);
    return {
      ...SearchEntityEvent(Actions.SEARCH_PAYMENT, filter, ['all'], [], 'paymentView'),
      paymentSearchPage,
    }

  }
})
