
import {defineComponent, ref, watch} from "vue";
import PaymentTypeLabel from "@/views/payment/PaymentTypeLabel.vue";
import PaymentStatus from "@/views/payment/PaymentStatusLabel.vue";
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import PaymentService from "@/core/services/PaymentService";
import {makeDownloadLink} from "@/core/helpers/functions";
import InvoiceService from "@/core/services/InvoiceService";

export default defineComponent({
  name: "PaymentViewCard",
  components: {QuickAction, CurrencyFormat, Row, PaymentStatus, PaymentTypeLabel},
  props: {
    isChecker: {type: Boolean, default: () => false},
    payment: {type: Object, required: true}
  },
  emits: ['add', 'remove', 'edit', 'delete'],
  setup(props) {
    const check = ref(false);
    watch(() => props.payment, () => {
      check.value = false;
    })
    return {
      ...LoadPanel(),
      check,
    }

  },
  methods: {
    onCheck() {
      if (this.check) {
        this.$emit('add', this.payment.paymentId)
      } else {
        this.$emit('remove', this.payment.paymentId)
      }
    },
    onDelete(payment) {
      this.$emit('delete', payment.paymentId)
    },
    onEdit(payment) {
      PaymentService.get(payment.paymentId).then(res => {
        this.$emit('edit', res);
      })
    },
    onDownloadGroupCheque(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.printGroupCheque(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onDownloadEOBs(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.printEOB(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onDownloadCheque(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.printCheque(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onDownloadEob(id, cheque: boolean) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportEOB(id, cheque).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
  }
})
